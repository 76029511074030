(function () {
	'use strict';

	angular
		.module('app')
		.controller('ApproveApplicantCtrl', approveApplicantCtrl);

	function approveApplicantCtrl($stateParams, applicantResource, $state) {
		/* jshint validthis:true */
		var vm = this;
		vm.approve = approve;
		vm.reject = reject;

		init();

		function init() {
			applicantResource.getApplicantByHash($stateParams.id)
				.then(function (applicant) {
					vm.applicant = applicant;
				});
		}

		function approve(app) {
			applicantResource.approveApplicant(app)
				.then(function () {
					alert('Applicant has been approved!');
					$state.go('applicant');
				});
		}

		function reject(app) {
			applicantResource.rejectApplicant(app)
				.then(function () {
					alert('Applicant has been declined!!');
					$state.go('applicant');
				});
		}

	}

})();
