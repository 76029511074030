(function () {
	'use strict';

	angular
		.module('app')
		.controller('OptionalNarrativeSummary', OptionalNarrativeSummary);

	function OptionalNarrativeSummary(applicantResource, userContext, simpleStorageService) {
		var vm = this;
		vm.showCodeError = false;
		vm.showErrors = false;
		vm.narrative = {};
		vm.narrativeSummary = {};
		vm.school = {};
		vm.user = userContext.getUser();
		vm.schoolYearId = simpleStorageService.getItem('schoolYearId');
		vm.submit = submit;

		activate();

		function activate() {
			applicantResource.getNarrative(vm.user.cdsCode, vm.schoolYearId)
				.then(function (narrative) {
					vm.narrative = narrative;
					vm.narrativeSummary.narrative = narrative.narrative;
				});
			applicantResource.getLEA(vm.user.cdsCode)
				.then(function (school) {
					vm.school = school;
				});
		}

		function submit() {
			//Clear Fields
			vm.narrativeSummary.cdsCode = vm.user.cdsCode;
			vm.narrativeSummary.schoolYearId = vm.schoolYearId;
			vm.narrativeSummary.id = vm.narrative.id;
			applicantResource.addNarrative(vm.narrativeSummary)
				.then(function () {
					alert('Narrative has been saved!');
				});
		}
	}
})();
