(function () {
	'use strict';

	angular
		.module('services')
		.factory('prioritiesResource', prioritiesResource);

	function prioritiesResource(dataservice) {

		var service = {
			addPriority: addPriority,
			getPriority: getPriority,
			getFormsLog: getFormsLog,
			resetSubmission: resetSubmission,
			deletePriority: deletePriority,
			saveAsDraft: saveAsDraft,
			getFormId: getFormId,
		};

		return service;

		function addPriority(item, priority) {
			delete priority.StatusType;
			return dataservice.postData('/priorities/Add' + priority, item);
		}
		function getFormId(priorityId, schoolYearId) {
			return dataservice.getData('/priorities/getFormId/' + priorityId + '/' + schoolYearId);
		}
		function getPriority(priorityId, schoolYear, formId) {
			return dataservice.getData('/priorities/GetPriority/' + priorityId + '/' + schoolYear + '/' + formId);
		}

		function getFormsLog(cds, id) {
			return dataservice.getData('/priorities/GetFormsLog/' + cds + '/' + id);
		}

		function saveAsDraft(priorityId, formId) {
			return dataservice.postData('/priorities/saveAsDraft/' + priorityId + '/' + (formId || 0));
		}

		function deletePriority(priorityId, schoolYearId) {
			return dataservice.postData('/priorities/DeletePriority/' + priorityId + '/' + schoolYearId);
		}

		function resetSubmission(priorityId, formId) {
			return dataservice.postData('/priorities/resetSubmission/' + priorityId + '/' + (formId || 0));

		}

	}


})();
