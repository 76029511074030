(function () {
	'use strict';

	angular
		.module('helpers')
		.factory('dataservice', dataservice);

	/* @ngInject */
	function dataservice($q, $http, appSettings) {
		var serviceBaseUrl = appSettings.serverPath + 'api';

		var service = {
			postData: postData,
			getData: getData,
		};
		return service;

		function postData(url, data) {
			var deferred = $q.defer();
			$http.post(serviceBaseUrl + url, data)
				.then(function (rsp) {
					deferred.resolve(rsp.data);
					return rsp;
				})
				.catch(function (err) {
					deferred.reject(err);
				});
			return deferred.promise;
		}

		function getData(url, cache) {
			cache = cache !== undefined ? cache : false;
			var deferred = $q.defer();
			$http.get(serviceBaseUrl + url, { cache: cache })
				.then(function (rsp) {
					deferred.resolve(rsp.data);
					return rsp;
				})
				.catch(function (err) {
					deferred.reject(err);
				});
			return deferred.promise;
		}

	}
})();
