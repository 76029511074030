(function () {
	'use strict';

	angular
		.module('app')
		.controller('ApplicantListCtrl', applicantListCtrl);

	function applicantListCtrl($filter, applicantResource, forms, priorityReport, userContext) {
		var vm = this;

		function splitByCapitalLetter(item) {
			var split = item.split(/(?=[A-Z])/);
			return split.every(function (x) { return x.length === 1; })
				? item
				: split
					.map(function (x) { return x.trim(); })
					.map(function (x) {
						return x.length === 1 || (x.length === 2 && x.charAt(1) === '.')
							? x.toUpperCase() + '\uFFFF'
							: (x.charAt(0).toUpperCase() + x.substring(1));
					})
					.join(' ')
					.replace(
						/\w{1}\.?(\uFFFF){1}\s?/g,
						function (x) {
							var first = x.charAt(0);
							var second = x.charAt(1);
							return second === '.'
								? first + second
								: first;
						}
					)
					.replace(
						/\.{1}\w{2,}/g,
						function (x) {
							return x.charAt(0) + ' ' + x.charAt(1).toUpperCase() + x.substring(2);
						}
					)
					.replace(/- /g, '-');
		}

		vm.formsHeaders = forms.length > 0
			? Object.keys(forms[0])
				.map(function (key) {
					return splitByCapitalLetter(key);
				})
			: [];
		vm.priorityHeaders = priorityReport.length > 0
			? Object.keys(priorityReport[0])
				.map(function (key) {
					return splitByCapitalLetter(key);
				})
			: [];

		vm.forms = forms;
		vm.priorityReport = priorityReport;
		vm.totalForms = forms;
		vm.deleteApplicant = deleteApplicant;
		vm.total = forms.length;
		vm.limit = 30;
		vm.selectedPage = 1;
		vm.forms = forms.slice(0, vm.limit);
		vm.filterForms = filterForms;
		vm.approve = approve;
		vm.sendLogin = sendLogin;
		vm.sendSuperEmail = sendSuperEmail;
		vm.changeUserPassword = changeUserPassword;
		vm.openEmailLog = openEmailLog;
		vm.text = 'Hello World';
		vm.user = userContext.getUser();
		vm.dateNow = new Date().toLocaleDateString();
		vm.emailLog = [];
		vm.pages = [];

		function deleteApplicant(formId) {
			if (confirm('Are you sure you want to delete?'))
				applicantResource.deleteApplicant(formId)
					.then(function () {
						vm.forms = vm.forms.filter(function (app) {
							return app.formId !== formId;
						});
					});

		}

		vm.totalPages = Math.ceil(forms.length / vm.limit);
		vm.selectPage = function selectPage(page) {
			if (page < 1 || page > vm.totalPages) return;

			vm.selectedPage = page;
			var pages = [];
			if (page < 9) {
				pages = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
				if (page > 6)
					pages.push(11);
				if (page > 7)
					pages.push(12);
			} else {
				for (var i = (vm.selectedPage - 5); i < (vm.selectedPage + 5); i++)
					if (i <= vm.totalPages)
						pages.push(i);
			}


			if (vm.selectedPage > 6) {
				pages.unshift(0);
				pages.unshift(1);
			}
			if (vm.selectedPage < (vm.totalPages - 6)) {
				pages.push(0);
				pages.push(vm.totalPages);
			}
			vm.pages = pages;

			var start = (vm.selectedPage - 1) * vm.limit;
			var end = (start + vm.limit > vm.total) ? vm.total : start + vm.limit;
			vm.forms = forms.slice(start, end);
		};
		vm.selectPage(1);


		function filterForms(search) {
			search = search.toLowerCase();
			vm.forms = forms.filter(function (obj) {
				return search ? ~obj.fName.toLowerCase().indexOf(search) || ~obj.lName.toLowerCase().indexOf(search) || ~obj.title.toLowerCase().indexOf(search) || ~obj.schoolName.toLowerCase().indexOf(search) : true;
			});
			if (vm.forms.length > vm.limit) vm.selectPage(vm.selectedPage);
		}


		function approve(app) {
			if (confirm('An email will get sent to ' + app.email + '. Click OK to continue.'))
				applicantResource.approveApplicant(app);

		}

		function sendLogin(app) {
			if (confirm('An email will get sent to ' + app.email + '. Click OK to continue.'))
				applicantResource.sendLogin(app);

		}

		function sendSuperEmail(app) {
			if (confirm('An approval email will be sent to ' + app.admEmail1 + '. Click OK to continue.'))
				applicantResource.sendSuperEmail({
					Hash: app.hash,
					Subject: app.fName + ' ' + app.lName,
					Data: app,
					Recipient: app.admEmail1,
				});

		}

		function changeUserPassword(user) {
			if (confirm('Are you sure you would like to change the password for this user? A randomly generated password will be sent via email to all coordinators and the superintendent associated with the LEA. Click OK to continue.'))
				applicantResource.changeUserPassword(user)
					.then(function () {
						alert('Password has been updated!');
					});

		}

		var dateFilter = $filter('date');
		var orderByFilter = $filter('orderBy');

		function openEmailLog(cdsCode, schoolYearId) {
			applicantResource.getEmailLog(cdsCode, schoolYearId)
				.then(function (emailLog) {
					vm.emailLog = orderByFilter(
						emailLog.map(function (email) {
							var date = new Date(email.date);
							date.setHours(date.getHours() - 7);
							email.date = dateFilter(date, 'short', '0000');
							email.recipients = email.recipients.split(',').join(', ');
							return email;
						}),
						'date',
						true,
						function (x, y) {
							var xVal = new Date(x).getTime();
							var yVal = new Date(y).getTime();
							return xVal === yVal ? 0 : (xVal > yVal ? 1 : -1);
						}
					);
					// @ts-ignore
					$('#email-log-modal').modal();
				});
		}

	}

})();
