(function () {
	'use strict';

	angular
		.module('services')
		.directive('datechooser', datechooser);

	/* @ngInject */
	function datechooser($timeout) {
		var directive = {
			link: link,
			restrict: 'A',
			scope: {
				ngModel: '=value',
				onChange: '=',
				isReset: '=?datechooserReset',
			},
		};
		return directive;

		function link(scope, element) {
			var initialLoad = true;
			var $el = $(element);

			//watch for changes
			scope.$watch('ngModel', function (newDate) {
				if (initialLoad) {
					var v = scope.ngModel == null ? '' : new Date(scope.ngModel).toLocaleDateString();
					$el
						.attr('value', v)
						.datepicker({
							autoclose: true,
							keyboardNavigation: false,
							container: $el.parent(),
							maxViewMode: 2,
							orientation: 'top',
						});

					$el
						.on('changeDate', handleDateEvent)
						.on('clearDate', handleDateEvent)
						.on('keypress', handleDateEvent);
					initialLoad = false;
					return;
				}

				if ($el.val() === newDate)
					return;


				if (scope.isReset) {
					scope.isReset = false;
					return;
				}

				$timeout(function () {
					$el.datepicker('update', newDate);
				});
			});

			function handleDateEvent() {
				$timeout(function () {
					scope.ngModel = $el.datepicker('getDate');

					if (scope.onChange)
						scope.onChange(scope.ngModel);


					//find form and mark dirty
					findForm(scope.$parent, function (form) {
						form.$dirty = true;
					});
				});
			}

			function findForm(parent, callback, counter) {
				if (parent == null) return;

				//recursively look for form controller, only go 5 levels deep looking
				if (counter === undefined)
					counter = 0;


				if (counter > 4)
					return; //bail out, probably not in a form


				//if parent has form property then return it, otherwise keep looking
				if (parent.form !== undefined) {
					callback(parent.form);
					return;
				}
				counter += 1;
				findForm(parent.$parent, callback, counter);
			}
		}
	}
}());
