(function () {
	'use strict';

	angular
		.module('app')
		.controller('AdminCtrl', adminCtrl);

	function adminCtrl($state, authService) {
		var vm = this;
		vm.form = {};
		vm.login = login;
		vm.logout = logout;
		vm.showError = false;

		function login(form) {
			if (form.$error.required)
				vm.showError = true;

			else
				authService.login(vm.form)
					.then($state.go.bind(null, 'admin.applicantList'));

		}

		function logout() {
			authService.logOut();
			$state.go('admin.login');
		}

	}

})();
