/* Help configure the state-base ui.router */
(function () {
	'use strict';

	angular
		.module('app')
		.provider('routerHelper', routerHelperProvider);

	/* @ngInject */
	function routerHelperProvider($stateProvider, $urlRouterProvider) {
		/* jshint validthis:true */
		var config = {
			docTitle: 'California School Dashboard ',
		};

		this.configure = function (cfg) {
			angular.extend(config, cfg);
		};

		this.$get = RouterHelper;

		/* @ngInject */
		function RouterHelper($rootScope, $state, authService) {
			var handlingStateChangeError = false;
			var hasOtherwise = false;
			var stateCounts = {
				errors: 0,
				changes: 0,
			};

			var service = {
				configureStates: configureStates,
				getStates: getStates,
				stateCounts: stateCounts,
				setTitle: setTitle,
			};

			init();

			return service;

			///////////////

			function configureStates(states, otherwisePath) {
				states.forEach(function (state) {
					if (state.config.allowAnonymous == null)
						state.config.allowAnonymous = false;
					$stateProvider.state(state.state, state.config);
				});
				if (otherwisePath && !hasOtherwise) {
					hasOtherwise = true;
					$urlRouterProvider.otherwise(otherwisePath);
				}
			}



			function handleStatechange() {
				$rootScope.$on('$stateChangeStart',
					function (event, toState) {

						if (authService.authentication() === null && !toState.allowAnonymous) {
							event.preventDefault();

							$state.go('coordinatorLogin', { returnTo: toState.url });//login page
							//logger.error('You must be logged in to access this page');

							// transitionTo() promise will be rejected with
							// a 'familyresources prevented' error
						}

					});
			}

			function handleRoutingErrors() {
				// Route cancellation:
				// On routing error, go to the dashboard.
				// Provide an exit clause if it tries to do it twice.
				$rootScope.$on('$stateChangeError',
					function (event, toState, _toParams, _fromState, _fromParams, error) {
						if (handlingStateChangeError)
							return;

						stateCounts.errors++;
						handlingStateChangeError = true;
						var destination = (toState &&
							(toState.title || toState.name || toState.loadedTemplateUrl)) ||
							'unknown target';
						alert('Error routing to ' + destination + '. ' + (error.ExceptionMessage || ''));
						$state.go('coordinatorHome');
						event.preventDefault();
					}
				);
			}

			function init() {
				handleRoutingErrors();
				handleStatechange();
				updateDocTitle();
			}

			function getStates() { return $state.get(); }

			function updateDocTitle() {
				$rootScope.$on('$stateChangeSuccess',
					function (_event, toState, _toParams, fromState, fromParams) {
						stateCounts.changes++;
						handlingStateChangeError = false;
						setTitle(toState.title);
						$rootScope.previousState = fromState.name;
						$rootScope.previousStateParams = fromParams;
						$rootScope.previousTitle = fromState.title;
						$rootScope.currentState = toState;
						authService.resetAutoLogout();
					}
				);
			}

			function setTitle(title) {
				$rootScope.title = config.docTitle + (title ? ' - ' + title : '');
				$rootScope.headerTitle = (title || '');
			}
		}
	}
})();
