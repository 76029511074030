(function () {
	'use strict';

	angular
		.module('app')
		.controller('ApplicantCtrl', applicantCtrl);

	function applicantCtrl($state, $scope, applicantResource, schools) {

		var vm = this;
		vm.addApplicant = addApplicant;
		vm.applicant = {};
		vm.school = {};
		vm.schools = schools;
		vm.user = {};
		vm.getUser = getUser;
		vm.showCodeError = false;
		vm.resetForm = resetForm;
		vm.showErrors = false;
		vm.showEmailError = false;
		vm.showEmailError2 = false;
		vm.schoolSearch = '';
		vm.selectedSchool = '';

		$scope.onSelect = function (schoolSearch) {
			//console.log(schoolSearch);
			if (typeof schoolSearch !== 'string' || schoolSearch.trim().length === 0)
				return;
			
			if (schoolSearch.indexOf(' – ') > -1) {
				var split = schoolSearch.split(' – ');
				schoolSearch = split[split.length - 1];
			}
			var cdscode = schoolSearch.substring(schoolSearch.length - 14);
			//console.log(cdscode);
			var schools = vm.schools.filter(function (x) {
				return [x.cdsCode, x.schoolName, x.district].indexOf(cdscode) > -1;
			});

			if (schools.length === 0) return;

			var school = schools[0];
			//console.log(school.cdsCode);
			vm.school = school;
			vm.applicant.districtCode = school.cdsCode;
			vm.schoolSearch = (school.schoolName || school.district) + ' – ' + school.cdsCode;
			vm.selectedSchool = vm.schoolSearch;
		};

		function addApplicant(app, form, school) {
			if (Object.keys(form.$error).length)
				if (form.$error.email)
					vm.showEmailError = true;
				else
					vm.showErrors = true;


			if (form.$valid) {
				app.schoolName = school.schoolName || school.district;

				var code = vm.schools.filter(function (result) {
					return result.cdsCode === school.cdsCode;
				});
				if (code.length === 0) {
					return vm.showCodeError = true;
				} else {
					app.charterNum = school.CharterNum || '0000';
					app.districtCode = school.cdsCode;
					app.schoolID = school.schoolID;

					applicantResource.addApplicant(app)
						.then(function () {
							$state.go('confirmation');
						});
				}
			}
		}

		function getUser() {
			applicantResource.getUser()
				.then(function (rsp) {
					vm.user = rsp;
				});
		}

		function resetForm() {
			vm.showErrors = false;
			vm.applicant = {};
			$scope.school = '';
		}

	}

})();
