(function () {
	'use strict';

	angular
		.module('app')
		.run(appRun);

	appRun.$inject = ['routerHelper'];

	function appRun(routerHelper) {
		routerHelper.configureStates(getStates(), '/coordinator/home');
	}

	function getStates() {
		return [{
			state: 'applicant',
			config: {
				url: '/application',
				templateUrl: 'app/applicant/applicant.html',
				title: 'Applicant Form',
				controller: 'ApplicantCtrl',
				controllerAs: 'vm',
				allowAnonymous: true,
				resolve: {
					/* @ngInject */
					schools: function (applicantResource) {
						return applicantResource.getSchools();
					},
				},
			},
		}, {
			state: 'home',
			config: {
				url: '/home',
				templateUrl: 'app/home/home.html',
				title: 'Home',
				controller: 'HomeCtrl',
				controllerAs: 'vm',
				allowAnonymous: true,
			},
		}, {
			state: 'confirmation',
			config: {
				url: '/confirmation',
				templateUrl: 'app/applicant/confirmation.html',
				title: 'Confirmation',
				allowAnonymous: true,
			},
		}, {
			state: 'admin.applicantList',
			config: {
				url: '/applicant/list',
				templateUrl: 'app/applicantList/applicantList.html',
				title: 'Applicant List',
				controller: 'ApplicantListCtrl',
				controllerAs: 'vm',
				resolve: {
					/* @ngInject */
					forms: function (applicantResource) {
						return applicantResource.getForms();
					},
					/* @ngInject */
					priorityReport: function (applicantResource, $filter) {
						return applicantResource.getPriorityReport()
							.then(function (reports) {
								return reports.map(function (report) {
									report.DateFinalized = $filter('date')(report.DateFinalized, 'short');
									return report;
								});
							});
					},
				},
			},
		}, {
			state: 'admin.applicant',
			config: {
				url: '/applicant/:formId',
				templateUrl: 'app/finishedApplicant/finishedApplicant.html',
				title: 'View Form',
				controller: 'FinishedApplicantCtrl',
				controllerAs: 'vm',
				resolve: {
					/* @ngInject */
					applicant: function (applicantResource, $stateParams) {
						return applicantResource.getApplicant($stateParams.formId);
					},
				},
			},
		}, {
			state: 'admin',
			config: {
				url: '/admin',
				template: '<ui-view/>',
				abstract: true,
			},
		}, {
			state: 'admin.login',
			config: {
				url: '/login',
				templateUrl: 'app/admin/login.html',
				title: 'Login',
				controller: 'AdminCtrl',
				controllerAs: 'vm',
				allowAnonymous: true,

			},
		}, {
			state: 'admin.changePassword',
			config: {
				url: '/changepassword',
				templateUrl: 'app/admin/changePassword/changePassword.html',
				title: 'Change Password',
				controller: 'ChangePasswordCtrl',
				controllerAs: 'vm',
				resolve: {
					/* @ngInject */
					user: function (applicantResource) {
						return applicantResource.getUser().then(function (rsp) {
							return rsp;
						});
					},
				},
			},
		}, {
			state: 'admin.approveApplicant',
			config: {
				url: '/approveApplicant/:id',
				templateUrl: 'app/admin/approveApplicant/approveApplicant.html',
				title: 'Approve Applicant',
				controller: 'ApproveApplicantCtrl',
				controllerAs: 'vm',
				allowAnonymous: true,
			},
		}, {
			state: 'coordinatorLogin',
			config: {
				url: '/coordinator',
				templateUrl: 'app/coordinator/coordinatorLogin/coordinatorLogin.html',
				title: 'Coordinator Login',
				controller: 'CoordinatorLoginCtrl',
				controllerAs: 'vm',
				allowAnonymous: true,
			},
		}, {
			state: 'coordinatorHome',
			config: {
				url: '/coordinator/home',
				templateUrl: 'app/coordinator/coordinatorHome.html',
				title: 'Coordinator Home',
				controller: 'CoordinatorHomeCtrl',
				controllerAs: 'vm',
			},
		}, {
			state: 'helpDesk',
			config: {
				url: '/helpdesk',
				templateUrl: 'app/helpDesk/helpDesk.html',
				title: 'Help Desk',
				controller: 'HelpDeskCtrl',
				controllerAs: 'vm',
				allowAnonymous: true,
			},
		}, {
			state: 'admin.leaSearch',
			config: {
				url: '/search',
				templateUrl: 'app/admin/leaSearch/leaSearch.html',
				title: 'Search',
				controller: 'LeaSearchCtrl',
				controllerAs: 'vm',
			},
		}, {
			state: 'priority01',
			config: {
				url: '/priorities/priority01',
				templateUrl: 'app/priorities/priority01/priority01.html',
				title: 'Priority 01',
				controller: 'Priority01Ctrl',
				controllerAs: 'vm',
				resolve: {
					/* @ngInject */
					formId: function (prioritiesResource, simpleStorageService) {
						return prioritiesResource.getFormId(1, simpleStorageService.getItem('schoolYearId'));
					},
					/* @ngInject */
					priority: function (prioritiesResource, simpleStorageService, formId) {
						return prioritiesResource.getPriority(
							1, simpleStorageService.getItem('schoolYearId'), formId
						);
					},
				},
			},
		},
		{
			state: 'priority02',
			config: {
				url: '/priorities/priority02',
				templateUrl: 'app/priorities/priority02/priority02.html',
				title: 'Priority 02',
				controller: 'Priority02Ctrl',
				controllerAs: 'vm',
				resolve: {
					/* @ngInject */
					formId: function (prioritiesResource, simpleStorageService) {
						return prioritiesResource.getFormId(2, simpleStorageService.getItem('schoolYearId'));
					},
					/* @ngInject */
					priority: function (prioritiesResource, simpleStorageService, formId) {
						return prioritiesResource.getPriority(
							2, simpleStorageService.getItem('schoolYearId'), formId
						);
					},
				},
			},
		},
		{
			state: 'priority03',
			config: {
				url: '/priorities/priority03',
				templateUrl: 'app/priorities/priority03/priority03.html',
				title: 'Priority 03',
				controller: 'Priority03Ctrl',
				controllerAs: 'vm',
				resolve: {
					/* @ngInject */
					formId: function (prioritiesResource, simpleStorageService) {
						return prioritiesResource.getFormId(3, simpleStorageService.getItem('schoolYearId'));
					},
					/* @ngInject */
					priority: function (prioritiesResource, simpleStorageService, formId) {
						return prioritiesResource.getPriority(
							3, simpleStorageService.getItem('schoolYearId'), formId
						);
					},
				},
			},
		}, {
			state: 'priority06',
			config: {
				url: '/priorities/priority06',
				templateUrl: 'app/priorities/priority06/priority06.html',
				title: 'Priority 06',
				controller: 'Priority06Ctrl',
				controllerAs: 'vm',
				resolve: {
					/* @ngInject */
					formId: function (prioritiesResource, simpleStorageService) {
						return prioritiesResource.getFormId(6, simpleStorageService.getItem('schoolYearId'));
					},
					/* @ngInject */
					priority: function (prioritiesResource, simpleStorageService, formId) {
						return prioritiesResource.getPriority(
							6, simpleStorageService.getItem('schoolYearId'), formId
						);
					},
				},
			},
		}, {
			state: 'priority07',
			config: {
				url: '/priorities/priority07',
				templateUrl: 'app/priorities/priority07/priority07.html',
				title: 'Priority 07',
				controller: 'Priority07Ctrl',
				controllerAs: 'vm',
				resolve: {
					/* @ngInject */
					formId: function (prioritiesResource, simpleStorageService) {
						return prioritiesResource.getFormId(7, simpleStorageService.getItem('schoolYearId'));
					},
					/* @ngInject */
					priority: function (prioritiesResource, simpleStorageService, formId) {
						return prioritiesResource.getPriority(
							7, simpleStorageService.getItem('schoolYearId'), formId
						);
					},
				},
			},
		}, {
			state: 'priority09',
			config: {
				url: '/priorities/priority09',
				templateUrl: 'app/priorities/priority09/priority09.html',
				title: 'Priority 09',
				controller: 'Priority09Ctrl',
				controllerAs: 'vm',
				resolve: {
					/* @ngInject */
					formId: function (prioritiesResource, simpleStorageService) {
						return prioritiesResource.getFormId(9, simpleStorageService.getItem('schoolYearId'));
					},
					/* @ngInject */
					priority: function (prioritiesResource, simpleStorageService, formId) {
						return prioritiesResource.getPriority(
							9, simpleStorageService.getItem('schoolYearId'), formId
						);
					},
				},
			},
		}, {
			state: 'priority10',
			config: {
				url: '/priorities/priority10',
				templateUrl: 'app/priorities/priority10/priority10.html',
				title: 'Priority 10',
				controller: 'Priority10Ctrl',
				controllerAs: 'vm',
				resolve: {
					/* @ngInject */
					formId: function (prioritiesResource, simpleStorageService) {
						return prioritiesResource.getFormId(10, simpleStorageService.getItem('schoolYearId'));
					},
					/* @ngInject */
					priority: function (prioritiesResource, simpleStorageService, formId) {
						return prioritiesResource.getPriority(
							10, simpleStorageService.getItem('schoolYearId'), formId
						);
					},
				},
			},
		}, {
			state: 'optionalNarrativeSummary',
			config: {
				url: '/NarrativeSummary',
				templateUrl: 'app/coordinator/optionalNarrativeSummary/optionalNarrativeSummary.html',
				title: 'Optional Narrative Summary',
				controller: 'OptionalNarrativeSummary',
				controllerAs: 'vm',
			},
		}];
	}

})();
