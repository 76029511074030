(function () {
	'use strict';

	angular
		.module('app')
		.controller('IndexCtrl', IndexCtrl);

	function IndexCtrl($q, $scope, $state, $timeout, authService, userContext, applicantResource, prioritiesResource, simpleStorageService) {
		var vm = this;

		vm.user = {};
		vm.forms = {};
		vm.school = {};

		vm.$state = $state;
		vm.isLoggedIn = authService.isLoggedIn();
		vm.logout = logout;

		$scope.$on('loginChanged', function (_e, isLoggedIn) {
			$timeout(function () {
				vm.isLoggedIn = isLoggedIn;
				if (isLoggedIn)
					getSchoolYearId().then(function (schoolYearId) {
						userContext.getUserFromServer().then(function (user) {
							vm.user = user;
							applicantResource.getLEA(user.cdsCode)
								.then(function (school) {
									vm.school = school;
									simpleStorageService.setItem('prioritySubmission', vm.school.PrioritySubmission);
									prioritiesResource.getFormsLog(vm.user.cdsCode, schoolYearId)
										.then(function (forms) {
											vm.forms = forms;
										});
								});
						});
					});
				else
					vm.logout();
			});
		});

		$scope.$on('formSubmitted', function () {
			getSchoolYearId().then(function (schoolYearId) {
				prioritiesResource.getFormsLog(vm.user.cdsCode, schoolYearId)
					.then(function (formsLog) {
						vm.forms = formsLog;
					});
			});
		});

		activate();

		function logout() {
			vm.user = {};
			authService.logOut();
			if (($state.is('admin.login') || $state.is('coordinatorLogin')) === false)
				if ($state.includes('admin'))
					$state.go('admin.login');
				else
					$state.go('coordinatorLogin');


		}

		function activate() {
			getSchoolYearId().then(function (schoolYearId) {
				vm.user = userContext.getUser();
				if (vm.user)
					applicantResource.getLEA(vm.user.cdsCode)
						.then(function (school) {
							vm.school = school;
							prioritiesResource.getFormsLog(vm.user.cdsCode, schoolYearId)
								.then(function (formsLog) {
									vm.forms = formsLog;
								});
						});
				else
					vm.user = {};
			});
		}

		function getSchoolYearId() {
			var schoolYearId = simpleStorageService.getItem('schoolYearId');

			if (schoolYearId != null)
				return $q.resolve(Number(schoolYearId));

			return applicantResource.getSchoolYearId().then(function (rsp) {
				simpleStorageService.setItem('schoolYearId', rsp);
				return rsp;
			});
		}
	}

})();
