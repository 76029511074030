(function() {
    'use strict';

    angular
        .module('app')
        .controller('CoordinatorLoginCtrl', coordinatorLoginCtrl);

    function coordinatorLoginCtrl($state, authService, userContext, applicantResource, simpleStorageService) {
        var vm = this;
        vm.login = login;
        vm.showError = false;

        function login(form, login) {
            if (login.$error.required) 
                {vm.showError = true;}
             else {
                var user = {};
                var email = form.countyCode + form.districtCode + form.schoolCode;
                user.username = email;
                user.password = form.password;
                authService.login(user)
                    .then(function() {
                        userContext.getUserFromServer();
                        applicantResource.getSchoolYearId()
                            .then(function(rsp) {
                                var schoolYearId = simpleStorageService.getItem('schoolYearId');
                                if (schoolYearId) 
                                    simpleStorageService.replaceItem('schoolYearId', rsp);
                                
                                if (!schoolYearId) 
                                    simpleStorageService.setItem('schoolYearId', rsp);
                                
                            });
                        $state.go('coordinatorHome');
                    });
            }
        }
    }
})();