(function () {
	'use strict';

	angular
		.module('app')
		.controller('FinishedApplicantCtrl', finishedApplicantCtrl);

	function finishedApplicantCtrl($state, $scope, $stateParams, applicantResource, applicant, userContext) {

		var vm = this;
		vm.applicant = applicant;
		vm.formId = $stateParams.formId;
		vm.editApplicant = editApplicant;


		getSchools();

		function getSchools() {
			applicantResource.getSchools()
				.then(function (schools) {
					vm.schools = schools;
				});
		}

		$scope.onSelect = function ($item) {
			vm.applicant.districtCode = $item.cdsCode;
		};

		function editApplicant(app, form) {
			if (Object.keys(form.$error).length)
				if (form.$error.email)
					vm.showEmailError = true;

				else
					vm.showErrors = true;




			if (form.$valid) {
				var user = userContext.getUser();
				app.adminId = user.userId;
				applicantResource.addApplicant(app)
					.then(function () {
						alert('Changes have been saved!');
						$state.go('admin.applicantList');
					});
			}
			//}
		}


	}

})();
