(function () {
	'use strict';

	var app = angular.module('app', [
		'services',
		'ui.router',
		'helpers',
		'ngSanitize',
		'ngCsv',
	]);

	app
		.constant('jquery', $)
		.run(function (authService) {
			authService.fillAuthData();
		})
		.config(function ($httpProvider, $locationProvider, $qProvider) {
			$httpProvider.interceptors.push('authInterceptorService');
			$locationProvider.html5Mode(true);
			$qProvider.errorOnUnhandledRejections(false);
		});

})();
