(function () {
	'use strict';

	var serverPath;
	switch (location.hostname) {
		case 'coordinator.caschooldashboard.org':
			serverPath = 'https://coordinatorapi.caschooldashboard.org/';
			break;
		case 'coordinatordev.caschooldashboard.org':
		case 'cde-coordinator-dev-caschooldashboard.azurewebsites.net':
			serverPath = 'https://cde-coordinator-dev-api-schooldashboard.azurewebsites.net/';
			break;
		case 'localhost':
			serverPath = 'https://localhost:5001/';
			break;
		default:
			throw new Error('Unknown client host: ' + location.hostname);
	}

	angular
		.module('services', [])
		.constant('appSettings', { serverPath: serverPath });
})();
