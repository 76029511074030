(function () {
	'use strict';

	angular
		.module('app')
		.controller('ChangePasswordCtrl', ChangePasswordCtrl);

	function ChangePasswordCtrl($location, applicantResource, user) {
		/* jshint validthis:true */
		var vm = this;
		vm.user = user;
		vm.changePassword = changePassword;
		vm.changePasswordConfirmation = '';
		vm.passwordError = false;
		vm.passwordSuccess = false;


		function changePassword(user) {
			if (user.password === vm.changePasswordConfirmation) {
				vm.passwordSuccess = true;
				vm.passwordError = false;
				if (vm.user.isAdmin === 'true') 
					{applicantResource.changePassword(user);}
				
				else if (vm.user.isAdmin === false) {
					var app = {
						password: vm.user.password,
						formId: vm.user.userId,
					};
					applicantResource.changeUserPassword(app);
				}

			}
			else {
				vm.passwordError = true;
				vm.passwordSuccess = false;
			}
		}

	}
})();
