/**
 * Created by mthomasson on 1/26/2015.
 */
(function () {
	'use strict';

	angular
		.module('services')
		.factory('authInterceptorService', authInterceptorService);

	function authInterceptorService($q, $rootScope, simpleStorageService) {

		var authInterceptorServiceFactory = {};

		var _request = function (config) {

			config.headers = config.headers || {};

			var authData = simpleStorageService.getItem('authorizationData');

			if (authData)
				config.headers.Authorization = 'Bearer ' + authData.token;


			return config;
		};

		var _responseError = function (rejection) {
			if (rejection.status === 401)
				$rootScope.broadcast('loginChanged', false);

			return $q.reject(rejection);
		};

		authInterceptorServiceFactory.request = _request;
		authInterceptorServiceFactory.responseError = _responseError;

		return authInterceptorServiceFactory;
	}
})();
