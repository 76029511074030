(function () {
	'use strict';

	angular
		.module('app')
		.controller('LeaSearchCtrl', leaSearchCtrl);

	function leaSearchCtrl(applicantResource) {
		var vm = this;

		vm.schools = [];
		vm.search = search;

		function search(item) {
			applicantResource.searchSchools(item)
				.then(function (schools) {
					vm.schools = schools;
					if (vm.schools.length === 0)
						alert('No matches found!');
				});
		}
	}

})();
