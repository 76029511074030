(function () {
	'use strict';

	angular
		.module('app')
		.controller('CoordinatorHomeCtrl', coordinatorHomeCtrl);

	function coordinatorHomeCtrl() {
	}
})();
