(function () {
	'use strict';

	angular
		.module('app')
		.controller('Priority03Ctrl', Priority03Ctrl);

	function Priority03Ctrl($rootScope, prioritiesResource, priority, simpleStorageService) {
		var vm = this;
		vm.showCodeError = false;
		vm.showErrors = false;
		vm.submit = submit;
		vm.priority = priority;
		vm.prioritySubmission = simpleStorageService.getItem('prioritySubmission');

		vm.options = [
			{ id: null, name: '--Select One--' },
			{ id: '1', name: '1 – Exploration and Research Phase' },
			{ id: 2, name: '2 – Beginning Development' },
			{ id: 3, name: '3 – Initial Implementation' },
			{ id: 4, name: '4 – Full Implementation' },
			{ id: 5, name: '5 – Full Implementation and Sustainability' },
		];

		activate();

		function activate() {
			priority.buildingRelationship = priority.buildingRelationship != null
				? priority.buildingRelationship.toString()
				: null;
			priority.environmentRelationship = priority.environmentRelationship != null
				? priority.environmentRelationship.toString()
				: null;
			priority.staffSupportRelationship = priority.staffSupportRelationship != null
				? priority.staffSupportRelationship.toString()
				: null;
			priority.opportunityRelationship = priority.opportunityRelationship != null
				? priority.opportunityRelationship.toString()
				: null;
			priority.professionalLearningPartnership = priority.professionalLearningPartnership != null
				? priority.professionalLearningPartnership.toString()
				: null;
			priority.resourcePartnership = priority.resourcePartnership != null
				? priority.resourcePartnership.toString()
				: null;
			priority.implementationPartnership = priority.implementationPartnership != null
				? priority.implementationPartnership.toString()
				: null;
			priority.legalRightsPartnership = priority.legalRightsPartnership != null
				? priority.legalRightsPartnership.toString()
				: null;
			priority.effectiveEngagementGroupDecision = priority.effectiveEngagementGroupDecision != null
				? priority.effectiveEngagementGroupDecision.toString()
				: null;
			priority.effectiveEngagementDecision = priority.effectiveEngagementDecision != null
				? priority.effectiveEngagementDecision.toString()
				: null;
			priority.strategyDecision = priority.strategyDecision != null
				? priority.strategyDecision.toString()
				: null;
			priority.familyEngagementDecision = priority.familyEngagementDecision != null
				? priority.familyEngagementDecision.toString()
				: null;
		}

		function submit(priority, form) {
			if (Object.keys(form.$error).length) {
				vm.showErrors = true;
				alert('Error submitting form. Please make sure all required fields are filled in');
			} else if (form.$valid) {
				if (confirm('Submitting a response will lock the form, preventing changes. To unlock the form, use the Clear Submission button; this will also permanently erase the submitted data. To avoid the lock, use the Save As Draft button instead. Continue with submission?')) {
					priority.StatusType = 2;
					prioritiesResource.addPriority(priority, 'Priority3')
						.then(function (rsp) {
							priority.formId = rsp;
							$rootScope.$broadcast('formSubmitted');
							alert('Your form has been submitted!');
						})
						.catch(function () {
							alert('There was an error submitting your form. Please try again.');
						});
				}
			}
		}
	}
})();
