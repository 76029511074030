(function () {
	'use strict';

	angular
		.module('services')
		.component('datePicker', {
			templateUrl: 'app/widgets/controls/input/datePickerTemplate.html',
			bindings: {
				ngModel: '=',
				onChange: '=',
				ngDisabled: '<',
				ngReadonly: '<',
				ngRequired: '<',
				isReset: '=?datechooserReset', // this is only used on add student page. Not sure that it is necessary
			},
			controller: DatePickerController,
		});

	/* @ngInject */
	function DatePickerController($element, $attrs) {
		var $ctrl = this;

		$ctrl.id = '';
		$ctrl.hasFocus = false;
		$ctrl.required = false;
		$ctrl.disabled = false;
		$ctrl.readonly = false;
		$ctrl.toggleDatepicker = toggleDatepicker;
		$ctrl.$onInit = activate;

		var input = $element[0].querySelector('input');
		var t = null;

		input.onblur = function onblur() {
			if (t != null) return;
			t = setTimeout(function () {
				$ctrl.hasFocus = false;
				t = null;
			}, 100);
		};

		function activate() {
			$ctrl.hasFocus = false;

			$ctrl.required = Object.prototype.hasOwnProperty.call($attrs, 'required');
			$ctrl.disabled = Object.prototype.hasOwnProperty.call($attrs, 'disabled');
			$ctrl.readonly = Object.prototype.hasOwnProperty.call($attrs, 'readonly');

			var ngModelParts = $attrs.ngModel.split('.');
			$ctrl.id = $attrs.id || ngModelParts[ngModelParts.length - 1]; //if you don't pass in id then it uses name of field

			$ctrl.toggleDatepicker = toggleDatepicker;
		}


		function toggleDatepicker() {
			var willFocus = !$ctrl.hasFocus;
			if (willFocus)
				input.focus();

			$ctrl.hasFocus = willFocus;
		}
	}
}());
