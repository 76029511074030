(function () {
	'use strict';

	angular
		.module('app')
		.controller('HomeCtrl', homeCtrl);

	function homeCtrl() {
	}

})();
