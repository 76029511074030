(function () {
	'use strict';

	angular
		.module('app')
		.controller('Priority02Ctrl', Priority02Ctrl);

	function Priority02Ctrl($rootScope, prioritiesResource, priority, simpleStorageService) {
		var vm = this;
		vm.showCodeError = false;
		vm.showErrors = false;
		vm.submit = submit;
		vm.priority = priority;
		vm.prioritySubmission = simpleStorageService.getItem('prioritySubmission');

		function submit(priority, form) {
			if (form.$dirty)  //Form has been touched
			{ isValid(form); }
			else if (Object.keys(form.$error).length) {
				vm.showErrors = true;
				alert('Error submitting form. Please make sure all required fields are filled in');
			} else if (form.$pristine) {
				if (priority.formId === 0)
					alert('Please fill out either the Option 1 section, or the Option 2 section. It is required for all questions to be answered in either one of those sections.');
				else
					isValid(form);
			}


		}

		// eslint-disable-next-line no-unused-vars
		function isValid(_form) {
			var fieldsToExclude = ['cdsCode', 'dateCreated', 'formId', 'LastModified', 'StatusType',
				'meetingDate', 'schoolYearId', 'priorityId', 'DateFinalized', 'additionalInfo', 'performance', 'nativeSummary',
				'activitySocial', 'activityScience',
			];
			var fieldsToCheck = Object.keys(vm.priority).filter(function (key) {
				return fieldsToExclude.indexOf(key) == -1;
			});
			var isOption1FilledIn = (vm.priority.nativeSummary || '' != '') ? true : false, isOption2FilledIn = false, fieldsNotFilledInTotal = 0;

			//Check Option 2 by iterating through all of the required fields
			for (var index = 0; index <= fieldsToCheck.length - 1; index++) {
				var value = vm.priority[fieldsToCheck[index]];
				if (value || value != null && !isNaN(value) && (value !== 0 || value != ''))
					continue;
				else
					fieldsNotFilledInTotal++;

			}
			isOption2FilledIn = fieldsNotFilledInTotal == 0;
			if (isOption1FilledIn || isOption2FilledIn)
				addPriority(priority);

			else if (!isOption1FilledIn && !isOption2FilledIn)
				alert('Please fill out either the Option 1 section, or the Option 2 section. It is required for all questions to be answered in either one of those sections.');

		}

		function addPriority(priority) {
			if (confirm('Submitting a response will lock the form, preventing changes. To unlock the form, use the Clear Submission button; this will also permanently erase the submitted data. To avoid the lock, use the Save As Draft button instead. Continue with submission?')) {
				priority.StatusType = 2;
				prioritiesResource.addPriority(priority, 'Priority2')
					.then(function (rsp) {
						priority.formId = rsp;
						$rootScope.$broadcast('formSubmitted');
						alert('Your form has been submitted!');
					})
					.catch(function () {
						alert('There was an error submitting your form. Please try again.');
					});
			}
		}
	}
})();
