(function () {
	'use strict';

	angular
		.module('app')
		.controller('Priority10Ctrl', Priority10Ctrl);

	function Priority10Ctrl($rootScope, prioritiesResource, priority, simpleStorageService) {
		var vm = this;
		vm.showCodeError = false;
		vm.showErrors = false;
		vm.submit = submit;
		vm.priority = priority;
		vm.prioritySubmission = simpleStorageService.getItem('prioritySubmission');

		function submit(priority, form) {
			if (Object.keys(form.$error).length) {
				vm.showErrors = true;
				alert('Error submitting form. Please make sure all required fields are filled in');
			} else if (form.$valid) {
				if (confirm('Submitting a response will lock the form, preventing changes. To unlock the form, use the Clear Submission button; this will also permanently erase the submitted data. To avoid the lock, use the Save As Draft button instead. Continue with submission?')) {
					priority.StatusType = 2;
					prioritiesResource.addPriority(priority, 'Priority10')
						.then(function (rsp) {
							priority.formId = rsp;
							$rootScope.$broadcast('formSubmitted');
							alert('Your form has been submitted!');
						})
						.catch(function () {
							alert('There was an error submitting your form. Please try again.');
						});
				}
			}
		}
	}
})();
