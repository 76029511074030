(function () {
	'use strict';

	var core = angular.module('app');
	core.factory('userContext', function ($q, dataservice, simpleStorageService) {
		var service = {
			getUser: getUser,
			getUserFromServer: getUserFromServer,
		};
		return service;

		function getUser() {
			return simpleStorageService.getItem('user');
		}

		function getUserFromServer() {
			var deferred = $q.defer();

			dataservice.getData('/Form/GetUser').then(function (user) {
				simpleStorageService.replaceItem('user', user);
				deferred.resolve(user);
			});
			return deferred.promise;
		}

	});

})();
