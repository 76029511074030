(function () {
	'use strict';

	angular
		.module('services')
		.factory('applicantResource', applicantResource);

	function applicantResource(dataservice) {

		var service = {
			addApplicant: addApplicant,
			sendEmail: sendEmail,
			getForms: getForms,
			getApplicant: getApplicant,
			getUser: getUser,
			changePassword: changePassword,
			getSchools: getSchools,
			deleteApplicant: deleteApplicant,
			getSchool: getSchool,
			sendSuperEmail: sendSuperEmail,
			getApplicantByHash: getApplicantByHash,
			approveApplicant: approveApplicant,
			sendLogin: sendLogin,
			sendConfirmEmail: sendConfirmEmail,
			changeUserPassword: changeUserPassword,
			rejectApplicant: rejectApplicant,
			searchSchools: searchSchools,
			addNarrative: addNarrative,
			getNarrative: getNarrative,
			getLEA: getLEA,
			getSchoolYearId: getSchoolYearId,
			getEmailLog: getEmailLog,
			getPriorityReport: getPriorityReport,
		};

		return service;


		function addApplicant(item) {
			return dataservice.postData('/form/AddApplicant', item);
		}

		function sendEmail(email, data) {
			return dataservice.postData('/form/SendEmail', email, data);
		}

		function getForms() {
			return dataservice.getData('/form/GetForms');
		}

		function getApplicant(formId) {
			return dataservice.getData('/form/finishedapplicant/' + formId);
		}

		function deleteApplicant(formId) {
			return dataservice.postData('/form/DeleteApplicant/' + formId);
		}

		function getUser() {
			return dataservice.getData('/form/GetUser');
		}

		function changePassword(item) {
			return dataservice.postData('/form/ChangePassword', item);
		}

		function getSchools() {
			return dataservice.getData('/form/GetSchools');
		}

		function getSchool(id) {
			return dataservice.getData('/form/GetSchool/' + id);
		}

		function sendSuperEmail(data) {
			return dataservice.postData('/form/SendSuperEmail', data);
		}

		function getApplicantByHash(string) {
			return dataservice.getData('/form/GetApplicantByHash/' + string);
		}

		function approveApplicant(app) {
			return dataservice.postData('/form/ApproveApplicant', app);
		}

		function sendLogin(app) {
			return dataservice.postData('/form/SendPasswordEmail?password=' + app.password, { Data: app, Recipient: app.email });
		}

		function rejectApplicant(app) {
			return dataservice.postData('/form/RejectApplicant', app);
		}

		function sendConfirmEmail(app) {
			return dataservice.postData('/form/SendConfirmEmail', app);
		}

		function changeUserPassword(app) {
			return dataservice.postData('/form/ChangeUserPassword', app);
		}

		function searchSchools(search) {
			return dataservice.postData('/form/SearchSchools/' + search);
		}

		function addNarrative(item) {
			return dataservice.postData('/form/AddNarrative/', item);
		}

		function getNarrative(cdsCode, schoolYearId) {
			return dataservice.getData('/form/GetNarrative/' + cdsCode + '/' + schoolYearId);
		}

		function getLEA(cdsCode) {
			return dataservice.getData('/form/GetLEA/' + cdsCode);
		}

		function getSchoolYearId() {
			return dataservice.getData('/form/GetSchoolYearId');
		}

		function getEmailLog(cdsCode, schoolYearId) {
			return dataservice.getData('/form/GetEmailLog/' + cdsCode + '/' + schoolYearId);
		}

		function getPriorityReport() {
			return dataservice.getData('/priorities/GetPriorityReport');
		}
	}

})();
