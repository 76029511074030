(function () {
	'use strict';

	angular
		.module('services')
		.factory('simpleStorageService', simpleStorageService);

	function simpleStorageService() {
		var service = {
			clear: clear,
			getItem: getItem,
			setItem: setItem,
			removeItem: removeItem,
			replaceItem: replaceItem,
		};

		return service;

		////////////////

		function clear() {
			sessionStorage.clear();
		}

		function getItem(key) {
			var item = sessionStorage.getItem(key);
			if (!item)
				return null;

			return JSON.parse(item);
		}

		function setItem(key, item) {
			var oldItem = getItem(key);
			if (oldItem && angular.isObject(item))
				item = angular.extend(key, item); //merge attributes


			return sessionStorage.setItem(key, JSON.stringify(item));
		}

		function removeItem(key) {
			return sessionStorage.removeItem(key);
		}

		function replaceItem(key, item) {
			removeItem(key);

			return sessionStorage.setItem(key, JSON.stringify(item));
		}

	}
})();
