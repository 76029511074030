(function () {
	'use strict';

	angular
		.module('app')
		.component('draftButton', {
			templateUrl: 'app/priorities/components/draft/draftButtonTemplate.html',
			controller: DraftButtonController,
			bindings: {
				priority: '=',
				priorityType: '<',
			},
		});

	/* @ngInject */
	function DraftButtonController($rootScope, prioritiesResource) {
		var $ctrl = /** @type {typeof DraftButtonController & { priority: any, priorityType: number, saveAsDraft: Function }} */(this);

		////////////////

		$ctrl.saveAsDraft = function () {
			prioritiesResource.saveAsDraft($ctrl.priorityType, $ctrl.priority.formId)
				.then(function (response) {
					if (response.IsSaveSuccessful) {
						$ctrl.priority.StatusType = 1;
						$ctrl.priority.LastModified = new Date();
						$ctrl.priority.formId = response.FormId;
						prioritiesResource.addPriority($ctrl.priority, 'Priority' + $ctrl.priorityType)
							.then(function () {
								alert('Save successful');
								$rootScope.$broadcast('formSubmitted');
							})
							.catch(function () {
								alert('Save unsuccessful');
							});
					}
				});
		};
	}
}());
