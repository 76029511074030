(function () {
    'use strict';

    angular
        .module('app')
        .controller('HelpDeskCtrl', helpDeskCtrl);

    function helpDeskCtrl() {

        var vm = this;
        vm.text = 'Hello World';
     

    }

})();