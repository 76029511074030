(function () {
	'use strict';

	angular
		.module('app')
		.component('resetButton', {
			templateUrl: 'app/priorities/components/reset/resetButtonTemplate.html',
			controller: ResetSubmissionController,
			bindings: {
				priority: '=',
			},
		});

	/* @ngInject */
	function ResetSubmissionController($rootScope, prioritiesResource) {
		var $ctrl = /** @type {typeof ResetSubmissionController & { priority: any, resetSubmission: Function }} */(this);

		$ctrl.resetSubmission = function resetSubmission() {
			if (confirm('Clearing the submission will permanently erase all submitted data. This cannot be undone. Continue?'))
				prioritiesResource.resetSubmission($ctrl.priority.priorityId, $ctrl.priority.formId)
					.then(function () {
						Object.keys($ctrl.priority).forEach(function (key) {
							$ctrl.priority[key] = undefined;
						});
						$rootScope.$broadcast('formSubmitted');
					});
		};
	}
}());
