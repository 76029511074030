(function () {
	'use strict';

	angular
		.module('services')
		.factory('authService', authService);

	function authService($http, $q, simpleStorageService, appSettings, $rootScope, $timeout) {

		var _authentication = {
			isAuth: false,
			userName: '',
			token: null,
		};
		var serviceBase = appSettings.serverPath;
		var autoLogoutPromise;


		var authServiceFactory = {
			login: login,
			logOut: logOut,
			fillAuthData: fillAuthData,
			authentication: getAuthentication,
			helpDeskLogin: helpDeskLogin,
			isLoggedIn: isLoggedIn,
			resetAutoLogout: resetAutoLogout,
		};

		return authServiceFactory;

		function resetAutoLogout() {
			if (autoLogoutPromise != null)
				$timeout.cancel(autoLogoutPromise);

			// set to show alert after 3 hours
			autoLogoutPromise = $timeout(autoLogout, 10800000);
		}

		function autoLogout() {
			alert('Your session has expired. You will now be routed to the login page');
			logOut();
		}

		function getAuthentication() {
			return simpleStorageService.getItem('authorizationData') || {
				isAuth: false,
				userName: '',
				token: null,
			};
		}

		function fillAuthData() {
			_authentication = getAuthentication();
		}

		function isLoggedIn() {
			return getAuthentication().isAuth;
		}

		function login(loginData) {
			var data = 'grant_type=password&username=' + loginData.username + '&password=' + loginData.password;

			var deferred = $q.defer();

			$http.post(serviceBase + 'token?clientID=printRequest', data, { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } })
				.then(function (response) {
					_authentication = {
						isAuth: true,
						userName: loginData.username,
						token: response.data.access_token,
					};

					simpleStorageService.replaceItem('authorizationData', _authentication);
					$rootScope.$broadcast('loginChanged', true);
					deferred.resolve(true);
					resetAutoLogout();
				})
				.catch(function (err) {
					logOut();
					deferred.reject(err);
					alert("Login information is not recognized. Please be sure to register as your LEA's Coordinator before attempting to login.");
				});

			return deferred.promise;
		}

		function logOut() {
			if (_authentication.isAuth)
				$rootScope.$broadcast('loginChanged', false);

			simpleStorageService.clear();

			_authentication = {
				isAuth: false,
				userName: '',
				token: null,
			};
		}

		function helpDeskLogin(item) {
			_authentication = item;
			simpleStorageService.replaceItem('authorizationData', item);
		}
	}
})();
